<template>
  <b-row class="justify-content-center">
    <b-col class="col-sm-12 col-md-8 col-lg-5">
      <form-wizard
        color="#F8AC18"
        :title="null"
        :subtitle="null"
        finish-button-text="Enviar"
        next-button-text="Próximo"
        back-button-text="Anterior"
        class="vertical-steps mb-3"
        @on-complete="getDataQuiz">
        <tab-content
          v-for="(quiz, i) in quizzes"
          :key="i"
          title=""
          icon="feather">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="quiz__title mb-0">
                {{ quiz.question }}
              </h5>
            </b-col>
            <b-col class="quiz__questions-container">
              <b-form-group
                v-for="(r, index) in quiz.reply"
                :key="index"
                label-for="name">
                <b-form-radio
                  v-model="dataQuiz[i]"
                  :name="i + 1"
                  :value="{
                    questionAnswerId: r.id
                  }">
                  {{ r.response }}
                </b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </b-col>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
// import vSelect from 'vue-select'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  // BButton,
  BRow,
  BCol,
  BFormGroup,
  // BFormInput,
  // BFormCheckbox,
  // BFormInvalidFeedback,
  BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    // BFormInput,
    // BFormCheckbox,
    // BFormInvalidFeedback,
    // vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadio,
    // BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userInvestorId: null,
      dataUser: {
        id: localStorage.getItem('id'),
        name: '',
      },
      dataQuiz: [],
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      quizzes: [],
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },

  async mounted() {
    const GetAllWithAnswer = await this.$http.get(
      '/api/services/app/QuestionProfiles/GetAllWithAnswer',
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    this.quizzes = GetAllWithAnswer.data.result.items.map(res => ({
      question: res.questionProfile.question,
      reply: res.answers,
    }))
  },

  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      this.teste_wizard()
    },
    check() {
      return 'check'
    },
    status() {
      return 'status'
    },
    teste_wizard() {},
    // eslint-disable-next-line consistent-return
    getDataQuiz() {
      const json = this.dataQuiz
      this.findData()
      if (json.length > 0) {
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const key in json) {
          // eslint-disable-next-line no-prototype-builtins
          if (json.hasOwnProperty(key)) {
            const data = json[key]
            setTimeout(() => this.alterData(data), 500)
          }
        }
        this.msgSuccess()
        setTimeout(() => {
          this.$router.push({ name: 'quiz-last' })
          return true
        }, 2700)
      }
    },
    async findData() {
      const response = await this.$http.get(
        `/api/services/app/UserInvestorProfiles/GetAllForUserId?UserId=${this.dataUser.id}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )

      if (response.data.result.items.length > 0) {
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const key in response.data.result.items) {
          // eslint-disable-next-line no-prototype-builtins
          if (response.data.result.items.hasOwnProperty(key)) {
            const data = response.data.result.items
            // eslint-disable-next-line guard-for-in,no-shadow,no-restricted-syntax
            for (const k in data) {
              const r = data[k]
              const f = r.userInvestorProfile.id
              this.userInvestorId = r.userInvestorProfile.id
              //this.deleteData(f)
            }
          }
        }
      }
      return response.data.result.items
    },
    async deleteData(id) {
      await this.$http.delete(
        `/api/services/app/UserInvestorProfiles/Delete?Id=${id}`,
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
    },
    async alterData(data) {
      try {
        data.userInvestorProfileId = this.userInvestorId
        await this.$http.post(
          '/api/services/app/InvestorReplies/CreateOrEdit',
          data,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
      } catch (error) {
        this.msgError()
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados gravados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import './sass/_quiz.scss';
</style>
