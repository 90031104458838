<template>
  <b-row>
    <QuizBackground />
    <b-col cols="12">
      <form-wizard-vertical-icon />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FormWizardVerticalIcon from './FormWizardVerticalIcon.vue'
import QuizBackground from './template/QuizBackground.vue'

export default {
  components: {
    BRow,
    BCol,
    FormWizardVerticalIcon,
    QuizBackground,
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';

.wizard-icon-circle {
  width: 20px !important;
  height: 0.4rem !important;
}

[dir] .vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle {
  background-color: #dadada !important;
}

.wizard-icon-circle.checked {
  background-color: #dadada !important;
}
[dir] .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgb(255 255 255) !important;
  width: 30px !important;
}

.vue-form-wizard {
  box-shadow: 0px 4px 25px 0px rgb(255 255 255 / 10%) !important;
}

.wizard-nav {
  border-bottom: 1px solid rgb(255 255 255 / 0%) !important;
  justify-content: center;
}

.wizard-card-footer {
  margin-left: 0 !important;
  flex-direction: column-reverse;
  display: flex;
}

.wizard-footer-right {
  width: 100% !important;
}
.wizard-footer-left {
  margin-top: 20px !important;
  text-align: center;
}
[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border: 1px solid #ffffff !important;
  color: #000 !important;
  font-weight: 500 !important;
}
[dir]
  .vue-form-wizard
  .wizard-card-footer
  .wizard-footer-left
  .wizard-btn:hover {
  background-color: #fff !important;
}
.wizard-btn::before,
.wizard-btn::after {
  content: '' !important;
}
[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  width: 100% !important;
}
</style>
